import * as React from 'react'
import { Component } from 'react';
import { Modal, DatePicker, Input, Form, Row, Icon, Spin } from 'antd-min';
import { DateHelper } from '@app/util/helper';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { GLGlobal, GLForm, FormItemsLayout, FormHelper, alignPop, LanguageDateFormat, GLFormProps, ComparisonOperator, MessageHelper, NotificationType, connect } from 'gl-commonui';
import * as moment from "moment";
import { rangeDateValider, lazyInject, TYPES, extendForm, textareaValider, fmtMsg } from '@app/util';
import { IVisitationService } from '@app/service/coach/visitation';
import { IUpcomingEventService } from '@app/service/coach/upcomingevent';
import { showDeleteInvitation, trimPlainText, formatDescription } from '@app/util/coach/fun';
import { ServerExceptionCode } from '@app/util/coach/enum';
import { setReloadCoachData } from '@app/states/dashboard';

interface VisitationLinksProps extends GLFormProps{
    data: any
    callback: () => void
    setReloadCoachData?: (d) => void
}

interface VisitationLinksStates {
    visible?: boolean
    action?: number
    spinning?: boolean
    isAcceptContent?: boolean
}
@GLForm.create()
@connect(null, {setReloadCoachData})
export class VisitationLinks extends Component<VisitationLinksProps, VisitationLinksStates> {
    @lazyInject(TYPES.IVisitationService)
    visitationservice: IVisitationService
    @lazyInject(TYPES.IUpcomingEventService)
    upcomingeventservice: IUpcomingEventService
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            spinning: false,
            isAcceptContent: false,
        }
    }
    handleCancel() {
        this.setState({  visible: false });
    }
    disableDates(current) {
        let disableDate = moment().add(-1, 'day').toDate();
        return current < disableDate;
    }
    getVisitation(data) {
        return this.visitationservice.getVisitation({ visitationId: data.visitationId });
    }
    getAdminContacts(params) {
        return this.upcomingeventservice.getAdminContacts(params);
    }
    accept() {
        const { data: { visitationId }, callback, setReloadCoachData } = this.props;
        const params = {
            visitationId: visitationId
        }
        this.setState({ spinning: true });
        this.visitationservice.acceptScheduleDate(params).then(() => {
            callback();
            this.setState({  visible: false, spinning: false });
            setReloadCoachData(true);
        }).catch(({ error_code, error_description }) => {
            if (error_code == ServerExceptionCode.NoTeachersError || error_code == ServerExceptionCode.VisitationIsNull) {
                MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationNotExist }));
            }
            this.setState({ spinning: false });
        });
    }
    suggest() {
        const { data: { visitationId }, callback } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const params = {
                    visitationId: visitationId,
                    startDate: values.startDate.toDate(),
                    endDate: values.endDate.toDate(),
                    note: trimPlainText(values.note),
                }
                this.setState({ spinning: true });
                this.visitationservice.suggestScheduleDate(params).then(() => {
                    callback();
                    this.setState({  visible: false, spinning: false });
                }).catch(({ error_code , error_description }) => {
                    if (error_code == ServerExceptionCode.VisitationIsNull) {
                        MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.VisitationNotExist }));
                    }
                    this.setState({ spinning: true });
                });;
            }
        });
    }
    delete() {
        const { data: { visitationId }, callback, setReloadCoachData } = this.props;
        const params = {
            id: visitationId
        }
        this.setState({ spinning: true });
        this.visitationservice.deleteVisitation(params).then(() => {
            callback();
            this.setState({  visible: false, spinning: false });
            setReloadCoachData(true);
        });
    }
    formatDate(date) {
        return DateHelper.formatDate2Local(date);
    }
    showAcceptModal() {
        this.setState({
            visible: true,
            isAcceptContent: true,
        });
    }
    lengthValidator(localeId, rigthVal) {
        return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
    }
    noteValidator(form) {
        return textareaValider(form, "note").concat(this.lengthValidator(SchoolLocale.VisitationRequestComments, 500));
    }
    getContent() {
        const { form, data: { tentativeStartDate, tentativeEndDate, tentativeExpiryDate } } = this.props;
        const { isAcceptContent, spinning } = this.state;
        const renderFormItem = FormHelper.renderFormItem;
        const dateValider = (msg) => {
            return rangeDateValider(form, "startDate", "endDate", msg);
        }
        return <div>
            <Spin spinning={spinning}>
            <div className="description">
                <div>{fmtMsg({ id: SchoolLocale.VisitationScheduleExpiryDate })}: {this.formatDate(tentativeExpiryDate)}</div>
                <Input.TextArea disabled autosize={{minRows: 2, maxRows: 6}} value={formatDescription(this.props.data)}></Input.TextArea>
            </div>
            {!isAcceptContent && <GLForm form={form}>
                <Row>{fmtMsg({ id: SchoolLocale.VisitationRequestTentativeDates })}</Row>
                <FormItemsLayout colTotal={2}>
                    {renderFormItem(
                        form,
                        SchoolLocale.ClassNewStartDate,
                        "startDate",
                        <DatePicker
                            {...alignPop({ type: "DatePicker" })}
                            format={
                                LanguageDateFormat[GLGlobal.intl.locale]
                            }
                            disabledDate={this.disableDates.bind(this)}
                        />,
                        tentativeStartDate ? DateHelper.toLocalMoment(tentativeStartDate) : null,
                        true,
                        dateValider(
                            fmtMsg({ id: GSAdminLocale.NotificationCreateEarlierDate })
                        )
                    )}
                    {renderFormItem(
                        form,
                        SchoolLocale.ClassNewEndDate,
                        "endDate",
                        <DatePicker
                            {...alignPop({ type: "DatePicker" })}
                            format={
                                LanguageDateFormat[GLGlobal.intl.locale]
                            }
                            disabledDate={this.disableDates.bind(this)}
                        />,
                        tentativeEndDate ? DateHelper.toLocalMoment(tentativeEndDate) : null,
                        true,
                        dateValider(
                            fmtMsg({ id: GSAdminLocale.NotificationCreateLaterDate })
                        )
                    )}
                </FormItemsLayout>
                <Form.Item className="reason">
                    {renderFormItem(extendForm(form), SchoolLocale.VisitationRequestComments, "note",
                            <Input.TextArea rows={4}/>, null, true, this.noteValidator(form))}
                </Form.Item>
            </GLForm>}
            </Spin>
        </div>
    }
    getFooter() {
        const { isAcceptContent } = this.state;
        const { data: { upcomingEventFeatureType }} = this.props;
        return <div className="footer">
            {isAcceptContent && <>
                <div onClick={() => this.accept()}><Icon type="check" /><span>{fmtMsg({ id: SchoolLocale.VisitationScheduleAccept })}</span></div>
                {showDeleteInvitation(upcomingEventFeatureType) &&
                <div onClick={() => this.delete()}><Icon type="close" /><span>{fmtMsg({ id: GSAdminLocale.ButtonDelete })}</span></div>}
            </>}
                {!isAcceptContent && <div onClick={() => this.onCancel() }><Icon type ="close" /><span>{fmtMsg({ id: GSAdminLocale.ButtonCancel })}</span></div>}
                <div onClick={() => this.proposeNewTime()}><Icon type="clock-circle" /><span>{fmtMsg({ id: SchoolLocale.VisitationScheduleProposeNewTime })}</span></div>
        </div>;
    }
    proposeNewTime() {
        const { isAcceptContent } = this.state;
        switch (isAcceptContent) {
            case true:
                this.setState({ isAcceptContent: false });
                break;
            case false:
                this.suggest();
                break;
        }
    }
    onCancel() {
        this.setState({ isAcceptContent: true });
    }
    render() {
        const { data: { title } } = this.props;
        const { visible } = this.state;
        return (<div className="visitation-links">
            <div onClick={() => this.showAcceptModal()}>
                {fmtMsg({ id: SchoolLocale.VisitationScheduleVisit })}
            </div>
            <Modal
                width="580px"
                wrapClassName="visitation-modal vertical-center-modal"
                title={title}
                visible={visible}
                footer={this.getFooter()}
                onCancel={() => this.handleCancel()}
            >
                {this.getContent()}
            </Modal>
        </div>)
    }
}
