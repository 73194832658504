import React, { Component } from 'react';
import { Checkbox } from 'antd-min';
import { MainTitle } from '@app/components';
import { SchoolLocale } from '../../locales/localeid';
import { PaginationParams, withRouter } from "gl-commonui";
import { IUpcomingEventService } from '@app/service/coach/upcomingevent';
import {  TYPES, lazyInject, fmtMsg } from '@app/util';
import { UpcomingEventList } from '@app/components/coach/upcoming-event-list';

interface UpcomingEventsProps {}
interface UpcomingEventsStates {
    hideArchived?: boolean
    list?: any[]
    loading: boolean
}

@withRouter
export class UpcomingEventsPage extends Component<UpcomingEventsProps, UpcomingEventsStates> {
    @lazyInject(TYPES.IUpcomingEventService)
    upcomingeventservice: IUpcomingEventService
    pagination: PaginationParams = new PaginationParams(1, 20)
    constructor(props) {
        super(props);
        this.state = { 
            hideArchived: true,
            list: [],
            loading: false,
        }
    }
    componentDidMount() {
        this.getUpcomingEvents();
    }
    getUpcomingEvents(current?) {
        this.pagination.current = current || this.pagination.current;
        this.setState({ loading: true});
        this.upcomingeventservice.getItemsBy({ hasExpired: !this.state.hideArchived, ...this.pagination.toRequest() })
            .then(data => {
                this.pagination.total = data.totalCount;
                this.setState({ 
                    list: data.data,
                    loading: false,
                });
            });
    }
    render() {
        const { list, loading, hideArchived } = this.state;
        return <div className="upcoming-events content-layout">
            <MainTitle plain={fmtMsg({id: SchoolLocale.HomeUpcomingEvents})} />
            <div className="page-content">
                <div><Checkbox className="show-archived" defaultChecked={!hideArchived} onChange={(e) => {
                    this.setState({ hideArchived: !e.target.checked }, () => {
                        this.getUpcomingEvents(1);
                    })
                }}>{fmtMsg({id: SchoolLocale.UpcomingEventShowExpiredEvents})}</Checkbox></div>
                <UpcomingEventList 
                    className="pendinglist" 
                    dataSource={list} 
                    loading={loading}
                    callback={() => this.getUpcomingEvents()}
                    pagination={list.length > 0 ? {
                        ...this.pagination,
                        onChange: page => this.getUpcomingEvents(page),
                    } : null}
                />
            </div>
        </div>;
    }
}

