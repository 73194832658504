import React, { Component } from 'react';
import { List } from "antd-min";
import moment from 'moment';
import { showSuggestAlternate, showPreStyle, formatDescription } from '@app/util/coach/fun';
import { UpcomingEventFeatureType } from '@app/util/coach/enum';
import { VisitationLinks } from '@app/page/dashboard/component/visitation-links';
import { UpcomingEventTitle, UpcomingEventDescription } from '@app/page/upcomingevents/components';
import { DateHelper, fmtMsg } from '@app/util';
import { SchoolLocale } from '@app/locales/localeid';

interface UpcomingEventListProps {
    dataSource?: any[]
    callback: () => void
    className?: string
    loading?: boolean
    pagination?: any
}
interface UpcomingEventListStates {}

export class UpcomingEventList extends Component<UpcomingEventListProps, UpcomingEventListStates> {
    showVisitationModal = () => {
        console.log("showVisitationModal");
    }
    formatDate(date) {
        return DateHelper.formatDate2Local(date);
    }
    render() {
        const { dataSource, loading, pagination, callback } = this.props;
        return <List 
            dataSource={dataSource}
            loading={loading}
            itemLayout="horizontal" 
            locale={{ emptyText: fmtMsg({ id: SchoolLocale.VisitationNoUpcomingEventsTips }) }} 
            pagination={pagination}
            renderItem={(v) => (
                <List.Item>
                    <List.Item.Meta
                        title={<>
                            <span className="title"><UpcomingEventTitle onClick={this.showVisitationModal} content={v} /></span>
                        </>}
                        description={<>
                            <UpcomingEventDescription 
                                className={showPreStyle(v.upcomingEventFeatureType) ? "pre" : ""}
                                onClick={this.showVisitationModal} content={formatDescription(v)} />
                            {!v.archived && showSuggestAlternate(v.upcomingEventFeatureType) && <>
                                <VisitationLinks data={v} callback={callback}/>
                            </>}
                        </>}
                    />
                </List.Item>
            )}></List>
    }
}